//
// Custom list group styles
// --------------------------------------------------

// In dropdown and overlay
.dropdown-menu,
.overlay {
  .list-group {
    margin: 0;
  }

  .list-group,
  .list-group-item {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  .list-group-item:first-child {
    border-top: none;
  }

  .list-group-item:last-child {
    border-bottom: none;
  }
}

// Make horizontal paddings match dropdown
.dropdown-menu .list-group-item {
  padding: 10px 20px;
}

// Loading message
.list-group-loading {
  padding: @line-height-computed * 2 @line-height-computed;
}

.list-group-loading-message {
  font-size: @font-size-large;
  text-align: center;
}

.list-group-loading-progress {
  max-width: 300px;
  margin: auto;

  background-color: @gray-lighter;
  border-radius: @border-radius-small;
  overflow: hidden;
}

.list-group-loading-progress-bar {
  width: 100%;
  height: 8px;
  background-color: @gray-light;
  animation: infiniteProgress 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes infiniteProgress {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.dropdown-menu .list-group-loading,
.overlay .list-group-lading {
  padding: @line-height-computed @line-height-computed * 2;
}

// Messages
.list-group-error,
.list-group-empty,
.list-group-message {
  padding: @line-height-computed;

  color: @text-color;
}

.list-group-error-icon,
.list-group-empty-icon,
.list-group-message-icon {
  margin-bottom: @line-height-computed;

  color: @gray-light;
  font-size: 64px;
  line-height: 64px;
  text-align: center;
}

.list-group-error-message,
.list-group-empty-message,
.list-group-message-message {
  margin: 0;

  font-size: @font-size-large;
  text-align: center;
}

.list-group-error-detail,
.list-group-message-detail {
  margin-top: @line-height-computed / 2;

  color: @gray;
  font-size: @font-size-large;
  text-align: center;
}
