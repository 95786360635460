//
// Overlay is a full page notifications list used on small devices
// --------------------------------------------------

.overlay {
  .notifications-list {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
  }

  .notifications-list-ready .list-group {
    position: absolute;
  }

  .list-group,
  .list-group-item {
    margin: 0;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  .list-group-item:first-child {
    border-top: none;
  }

  .list-group-item {
    border-bottom: none;
  }

  .notifications-list-item {
    align-items: flex-start;
    position: relative;

    // Make sidde paddings match rest of dropdown
    padding: 10px 20px;
  }

  .notifications-list-item-right-col {
    display: block;
  }

  .notifications-list-item-col-read-icon {
    position: absolute;
    padding: 0;
    top: 14px;
    right: 15px;
  }

  .notifications-list-item-col-timestamp {
    margin-top: floor(@line-height-computed / 4);
    padding: 0;
  }

  .notifications-list-item-unread {
    padding-right: 40px;
  }

  // Hide read indicator on read notifications
  .notifications-list-item-read .notifications-list-item-col-read-icon {
    display: none;
  }

  // Style loading/empty/error states
  .notifications-list-pending,
  .notifications-list-pending .list-group {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .notifications-list-pending .list-group-item {
    width: 100%;
  }
}
