.threads-replies {
  display: flex;
  align-items: center;

  color: @thread-replies-color;
  font-size: @thread-replies-size;
  font-weight: bold;

  .material-icon {
    font-size: 12px;
    margin-right: @grid-gutter-width / 4;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}

@media screen and (max-width: @screen-xs-max) {
  .threads-replies {
    font-size: @thread-replies-size-xs;

    .material-icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }
}
