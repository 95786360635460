// Set height and width of nav dropdown
.site-nav-dropdown {
  max-height: calc(100vh - @dropdown-height-offset);
  overflow-y: auto;
  width: 320px;
}

// Spread and align items in categories
.site-nav-category a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Hide sign in menu on larger devices
@media screen and (min-width: @screen-sm-min) {
  .site-nav-sign-in-message,
  .site-nav-sign-in-options {
    display: none;
  }
}

.site-nav-menu.overlay-menu-list {
  height: 100%;
  overflow-y: auto;
  padding-bottom: @line-height-computed;
}
